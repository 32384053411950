@import "../variables";
@import "../animations";
@import "../mixins";

#not-found-container,
#forbidden-access-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div:first-of-type {
    position: absolute;
    font-size: 50rem;
    font-weight: bold;
    color: $main_white;
    z-index: 0;
    text-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
    opacity: 0.7;
  }

  & > div:not(div:first-of-type) {
    z-index: 10;
    font-size: 4rem;
  }

  & button {
    padding: .5rem 2rem;
    font-size: 2rem;
    border-radius: 20px;
    z-index: 10;
    background: linear-gradient(0deg, $main_blue_darker, $main_blue_lighter);
    transition: all 0.3s ease;
    border: none;
    box-shadow: none;

    &:hover {
      transform: scale(1.2);
    }

    &:active {
      filter: brightness(1.2);
    }
  }
}