@import '../../variables';
@import '../../animations';
@import '../../mixins';

#container {
  border-radius: 20px;
  background-color: $main_white;
  border: none;
  box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;

  .form-label {
    color: $main_blue_darker;
    font-weight: bold;
  }

  .form-control {
    box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 5px #ffffff;
    border-radius: 20px;
    border-color: transparent;

    &:focus {
      box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 5px #ffffff;
      border: 1px solid $main_blue_lighter;
    }
  }

  button {
    border-radius: 20px;
  }
}