@import '../variables';
@import '../animations';
@import '../mixins';

#admin-dashboard-container {
	width: $main_content_width;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 100vh;
	display: flex;

	& .nav-item,
	& .nav-link {
		all: unset;
	}

	& .nav-link {
		width: 100px;
		text-align: center;
		font-size: 1.2rem;
		font-weight: bold;
		color: $main_blue_darker;
		padding: 1rem;
		border-radius: 20px !important;
		background-color: $main_white;
		box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
		border: none;
		outline: none;

		&:first-of-type {
			margin-right: 1rem;
		}
	}

	& .nav-tabs {
		border: none;
	}

	#admin-tabs-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.tab-content {
			width: 100%;
			max-width: 900px;
			height: 100%;
			max-height: 80vh;
			display: flex;

			& .tab-pane {
				width: 100%;
			}
		}
	}

	.active {
		border: none !important;
		background-color: $main_white !important;
		color: $main_blue_lighter !important;
	}
}

.formContainer:nth-of-type(1) {
	#permissionSelection {
		.form-label {
			font-weight: normal;
		}

		input[type='checkbox']:nth-of-type(1),
		input[type='checkbox']:nth-of-type(2) {
			margin-right: 0.5rem;
		}
	}
}

.formContainer:nth-of-type(2) {
	height: 10rem;
}

.formContainer {
	width: 400px;
	//max-height: 50vh;
	max-height: 35rem;
	margin-right: 3rem;
	//box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
	//border-radius: 20px;
	padding: 1rem;

	.form-label {
		color: $main_blue_darker;
		font-weight: bold;
	}

	.form-control,
	.form-select {
		box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 5px #ffffff;
		border-radius: 20px;
		border-color: transparent;

		&:focus {
			box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 5px #ffffff;
			border: 1px solid $main_blue_lighter;
		}
	}

	button {
		border-radius: 20px;
		width: 100%;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
}

#permissionSelection {
	input[type='checkbox'] {
		width: 0;
		height: 0;
		visibility: hidden;
	}

	label {
		cursor: pointer;
		width: 20px;
		height: 10px;
		background-color: grey;
		display: block;
		border-radius: 10px;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 1px;
			left: 1px;
			width: 8px;
			height: 8px;
			background-color: $main_white;
			border-radius: 9px;
			transition: 0.3s;
		}

		&:active::after {
			width: 13px;
		}
	}

	input[type='checkbox']:checked + label {
		background-color: $main_blue_darker;
	}

	input[type='checkbox']:checked + label::after {
		left: calc(100% - 1px);
		//right: .5px;
		transform: translateX(-100%);
	}
}
