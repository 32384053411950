@import "../../variables";
@import "../../animations";
@import "../../mixins";

#context-menu {
  position: absolute;
  min-width: 140px;
  background-color: $main_white;
  border-radius: 10px;
  box-shadow: 0 0 15px #dfdfdf;
  padding: 1rem;
  z-index: 1000;

  ul {
    list-style: none;

    li {
      cursor: pointer;
      transition: all 0.3s ease;
      background: transparent;

      &:hover {
        padding-left: 1rem;
        border-radius: 5px;
        background: linear-gradient(270deg, $main_blue_lighter, $main_blue_darker);
        color: $main_white;
      }
    }
  }
}

.exit-animation {
  animation: exit-animation .3s ease;
}