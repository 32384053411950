@import "./variables";

// Loader animation
@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }

  90% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Paper plane flight animation
@keyframes paper-plane-fly {
  10% {
    top: 3px;
    left: -3px;
  }

  100% {
    top: -40px;
    left: 35px;
  }
}

// Exit "dissolve" animation
@keyframes exit-animation {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0;
  }
}

// Selected File border animation
@keyframes selected-border {
  0% {
    border: 1px solid $main_blue_lighter;
  }
  50% {
    border: 2px solid $main_blue_darker;
  }
  100% {
    border: 1px solid $main_blue_lighter;
  }
}

// Bounce animation
@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  35% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes float-down {
  from {
    transform: translateY(-4vh);
    //top: 40%;
    opacity: 0;
  }

  to {
    transform: translateY(0);
    //top: 50%;
    opacity: 1;
  }
}


@keyframes reveal {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    scale: 0;
  }

  to {
    opacity: 0;
    scale: 2;
  }
}