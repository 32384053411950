@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes paper-plane-fly {
  10% {
    top: 3px;
    left: -3px;
  }
  100% {
    top: -40px;
    left: 35px;
  }
}
@keyframes exit-animation {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0;
  }
}
@keyframes selected-border {
  0% {
    border: 1px solid #1059c8;
  }
  50% {
    border: 2px solid #003584;
  }
  100% {
    border: 1px solid #1059c8;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes float-down {
  from {
    transform: translateY(-4vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ripple {
  from {
    opacity: 1;
    scale: 0;
  }
  to {
    opacity: 0;
    scale: 2;
  }
}
.file {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: #003584;
  border-radius: 15px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  margin: 1rem;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file svg {
  font-size: 4rem;
  margin-bottom: 1rem;
}
.file img {
  width: 48px;
}
.file:hover {
  transform: scale(1.1);
  box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 5px #ffffff;
}
.file p {
  width: 90%;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  margin: 0;
}

#file-modal .modal-content {
  border: none;
  border-radius: 20px !important;
}
#file-modal .modal-content a {
  color: inherit;
  text-decoration: none;
}
#file-modal .modal-content a:hover {
  color: inherit;
}
#file-modal .modal-content .modal-body.file-modal div {
  width: 50%;
}
#file-modal .modal-content .modal-body.file-modal div img,
#file-modal .modal-content .modal-body.file-modal div p {
  width: 100%;
  max-height: 128px;
  max-width: 128px;
}
#file-modal .modal-content .modal-body.file-modal div img {
  filter: drop-shadow(0 0 3px #aaa);
}
#file-modal .modal-content h5:not(h5:first-of-type),
#file-modal .modal-content h4 {
  color: #1059c8;
}
#file-modal .modal-content .file-modal button:first-of-type:not(.btn-close) {
  border-bottom-left-radius: 10px;
}
#file-modal .modal-content button:last-of-type {
  border-bottom-right-radius: 10px;
}

#selected {
  border: 1.5px dashed #1059c8;
}

