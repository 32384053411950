@import '../../variables';
@import '../../animations';
@import '../../mixins';

.modal-history {
	overflow: hidden;

	.modal-dialog {
		.modal-content {
			overflow: hidden;
		}

		max-height: 550px;
		min-height: 200px;
		height: 100%;

		width: 100%;
		max-width: 800px;
		min-width: 400px;

		& > div {
			height: 100%;
			width: 100%;
		}

		& .modal-body {
			overflow: auto;

			& .accordion-header h4 b {
				color: $main_blue_lighter;
			}
		}
	}
}
