@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes paper-plane-fly {
  10% {
    top: 3px;
    left: -3px;
  }
  100% {
    top: -40px;
    left: 35px;
  }
}
@keyframes exit-animation {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0;
  }
}
@keyframes selected-border {
  0% {
    border: 1px solid #1059c8;
  }
  50% {
    border: 2px solid #003584;
  }
  100% {
    border: 1px solid #1059c8;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes float-down {
  from {
    transform: translateY(-4vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ripple {
  from {
    opacity: 1;
    scale: 0;
  }
  to {
    opacity: 0;
    scale: 2;
  }
}
#landing-page-container {
  background-color: #FFFFFF;
  padding-top: 10vh;
  position: relative;
  width: 100vw;
}
#landing-page-container header {
  width: 100%;
  height: 10vh;
  top: 0;
  background-color: #FFFFFF;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3.2rem;
  box-shadow: 0 0 5rem 0 #FFFFFF;
  z-index: 100;
}
#landing-page-container header > .logo {
  font-size: 2.4rem;
  letter-spacing: 0.5rem;
  font-weight: bold;
  color: #0a53be;
}
#landing-page-container header > ul {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  height: 100%;
}
#landing-page-container header > ul > li {
  text-transform: uppercase;
  list-style: none;
  font-size: 1.6rem;
  letter-spacing: 0.25rem;
  transition: all 0.3s ease;
  position: relative;
  opacity: 0;
}
#landing-page-container header > ul > li > a {
  text-decoration: none;
  color: #000000;
}
#landing-page-container header > ul > li > a:hover {
  color: #1059c8;
}
#landing-page-container header > ul > li:not(#landing-page-container header > ul > li:last-of-type)::before {
  content: "";
  position: absolute;
  height: 0.125em;
  top: 100%;
  left: 50%;
  width: 0;
  transition: all 0.3s ease;
}
#landing-page-container header > ul > li:hover:not(#landing-page-container header > ul > li:last-of-type) {
  cursor: pointer;
  color: #1059c8;
}
#landing-page-container header > ul > li:hover:not(#landing-page-container header > ul > li:last-of-type)::before {
  width: 110%;
  left: -5%;
  background-color: #1059c8;
}
#landing-page-container header > ul > li:last-of-type > a {
  color: #FFFFFF;
}
@media screen and (max-width: 767px) {
  #landing-page-container header > ul {
    margin: 0 5vw;
  }
  #landing-page-container header > ul > li {
    display: none;
  }
}
#landing-page-container #progress-bar {
  display: block;
  height: 0.33vh;
  background: linear-gradient(90deg, #003584, #1059c8);
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
#landing-page-container #progress-bar-placeholder {
  display: block;
  height: 0.33vh;
  width: 100vw;
  background: #E4E4E4;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
#landing-page-container #hero-section {
  width: 100%;
  height: 75vh;
  background-color: rgba(16, 89, 200, 0.0980392157);
  border-bottom-right-radius: 100rem;
  position: relative;
  display: flex;
  align-items: center;
}
#landing-page-container #hero-section .text-section {
  display: flex;
  flex-direction: column;
  padding-left: 6.4rem;
  width: 75rem;
}
#landing-page-container #hero-section .text-section h1 {
  font-size: 7.2rem;
  font-weight: bold;
  letter-spacing: 0.2em;
  margin-bottom: 3.2rem;
  line-height: 5.6rem;
  opacity: 0;
}
#landing-page-container #hero-section .text-section h4 {
  font-size: 2rem;
  margin-bottom: 3.2rem;
  opacity: 0;
}
#landing-page-container #hero-section .text-section a {
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  width: min-content;
  color: #FFFFFF;
  padding: 1rem 3.2rem;
  opacity: 0;
}
#landing-page-container #hero-section #leader-illustration {
  position: absolute;
  width: 40vw;
  right: 6.4rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
#landing-page-container #hero-section #leader-illustration > g {
  opacity: 0;
}
@media screen and (max-width: 767px) {
  #landing-page-container #hero-section {
    flex-direction: column;
  }
  #landing-page-container #hero-section > div:first-of-type {
    width: 80%;
    padding: 5vh 0;
    height: 90%;
    justify-content: space-between;
  }
  #landing-page-container #hero-section > div:first-of-type > h1 {
    font-size: 3.2rem;
    text-align: center;
  }
  #landing-page-container #hero-section > div:first-of-type > h4 {
    display: none;
  }
  #landing-page-container #hero-section > #leader-illustration {
    left: 75%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
}
#landing-page-container #scroll-section {
  position: relative;
  width: 100vw;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 7vw;
  margin: 15vh auto;
}
#landing-page-container #scroll-section > div {
  width: 20vw;
}
#landing-page-container #scroll-section > div > h3 {
  font-size: 3.2rem;
  text-align: center;
}
#landing-page-container #scroll-section > div > svg {
  height: 400px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  #landing-page-container #scroll-section {
    flex-direction: column;
    width: 90%;
    gap: 5vh;
  }
  #landing-page-container #scroll-section > div {
    width: 100%;
  }
  #landing-page-container #scroll-section > div > h3 {
    font-size: 1.6rem;
    text-align: center;
  }
  #landing-page-container #scroll-section > div > svg {
    height: 200px;
    width: 100%;
  }
}
#landing-page-container #client-section,
#landing-page-container #slovenia-section {
  width: 100vw;
  height: 75vh;
  background-color: rgba(16, 89, 200, 0.0980392157);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5vw;
  gap: 5vw;
}
#landing-page-container #client-section > div,
#landing-page-container #slovenia-section > div {
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#landing-page-container #client-section > div > h3,
#landing-page-container #slovenia-section > div > h3 {
  font-size: 4.8rem;
  font-weight: 600;
  margin-bottom: 3.2rem;
}
#landing-page-container #client-section > div > p,
#landing-page-container #slovenia-section > div > p {
  font-weight: 100;
  font-size: 2.4rem;
}
#landing-page-container #client-section > svg,
#landing-page-container #slovenia-section > svg {
  width: 40vw;
}
@media screen and (max-width: 767px) {
  #landing-page-container #client-section,
  #landing-page-container #slovenia-section {
    flex-direction: column;
    height: auto;
  }
  #landing-page-container #client-section > div,
  #landing-page-container #slovenia-section > div {
    text-align: center;
  }
  #landing-page-container #client-section > div > h3,
  #landing-page-container #slovenia-section > div > h3 {
    font-size: 3.2rem;
  }
  #landing-page-container #client-section > div > p,
  #landing-page-container #slovenia-section > div > p {
    font-size: 1.6rem;
  }
  #landing-page-container #client-section > svg,
  #landing-page-container #slovenia-section > svg {
    height: 300px;
    width: 90%;
  }
}
#landing-page-container #client-section {
  border-top-left-radius: 100rem;
}
@media screen and (max-width: 767px) {
  #landing-page-container #slovenia-section {
    flex-direction: column-reverse;
  }
}
#landing-page-container #jokra-cloud-info-section {
  padding: 5vw 0 0 5vw;
  background: linear-gradient(to bottom, rgba(16, 89, 200, 0.0980392157), rgba(255, 255, 255, 0));
}
#landing-page-container #jokra-cloud-info-section > h1 {
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  font-size: 6.4rem;
  font-weight: bold;
}
#landing-page-container #jokra-cloud-info-section > h3 {
  font-size: 2.4rem;
}
@media screen and (max-width: 767px) {
  #landing-page-container #jokra-cloud-info-section {
    padding: 3.2rem 0 0 3.2rem;
  }
  #landing-page-container #jokra-cloud-info-section > h1 {
    font-size: 3.2rem;
  }
  #landing-page-container #jokra-cloud-info-section > h3 {
    font-size: 1.6rem;
  }
}
#landing-page-container #jokra-cloud-section {
  width: 100vw;
  height: 90vh;
  position: relative;
  margin-bottom: 20vh;
}
#landing-page-container #jokra-cloud-section > div {
  background: linear-gradient(180deg, #003584, #1059C8);
  position: absolute;
  left: 50%;
  top: 5%;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
}
#landing-page-container #jokra-cloud-section > div > div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3.2rem;
}
#landing-page-container #jokra-cloud-section > div > div:first-of-type > img {
  aspect-ratio: 1/1;
  width: 3.2rem;
  filter: invert(1);
  transition: filter 0.3s ease;
}
#landing-page-container #jokra-cloud-section > div > div:first-of-type > img:hover {
  cursor: pointer;
  filter: invert(0.75);
}
#landing-page-container #jokra-cloud-section > div > div:first-of-type > img:first-of-type {
  rotate: 180deg;
}
#landing-page-container #jokra-cloud-section > div > div:last-of-type {
  text-align: center;
  color: #FFFFFF;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
#landing-page-container #jokra-cloud-section > div > div:last-of-type > div {
  position: absolute;
}
#landing-page-container #jokra-cloud-section > div > div:last-of-type h4 {
  font-size: 4.8rem;
  margin-bottom: 3.2rem;
}
#landing-page-container #jokra-cloud-section > div > div:last-of-type p {
  font-size: 1.6rem;
}
#landing-page-container #jokra-cloud-section > div .carousel-info-wrapper > div {
  display: none;
}
#landing-page-container #jokra-cloud-section > div .carousel-info-wrapper > div:first-of-type {
  display: block;
}
#landing-page-container #jokra-cloud-section > div .carousel {
  position: relative;
  max-width: 1200px;
  width: 100%;
  height: 500px;
  padding: 5vh 0;
}
#landing-page-container #jokra-cloud-section > div .carousel > div {
  aspect-ratio: 16/9;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  box-shadow: 0 0 10rem 0 rgba(0, 0, 0, 0.25);
  transition: all 500ms ease-in-out;
}
#landing-page-container #jokra-cloud-section > div .carousel > div > img {
  width: 100%;
  transition: all 500ms ease-in-out;
}
#landing-page-container #jokra-cloud-section > div .carousel > div.carousel-item-1 {
  left: 30%;
  height: 350px;
}
#landing-page-container #jokra-cloud-section > div .carousel > div.carousel-item-1 > img {
  opacity: 0.75;
}
#landing-page-container #jokra-cloud-section > div .carousel > div.carousel-item-2 {
  left: 50%;
  z-index: 3;
  height: 400px;
  opacity: 1;
}
#landing-page-container #jokra-cloud-section > div .carousel > div.carousel-item-3 {
  left: 70%;
  height: 350px;
}
#landing-page-container #jokra-cloud-section > div .carousel > div.carousel-item-3 > img {
  opacity: 0.75;
}
@media screen and (max-width: 767px) {
  #landing-page-container #jokra-cloud-section {
    margin-bottom: 40vh;
  }
}
#landing-page-container #contact-section {
  width: 100vw;
  height: 90vh;
  display: flex;
  position: relative;
}
#landing-page-container #contact-section > div:first-of-type {
  width: 50vw;
  padding: 0 2.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#landing-page-container #contact-section > div:first-of-type > svg {
  height: 70%;
  width: 100%;
}
#landing-page-container #contact-section > div:first-of-type > div {
  text-align: center;
}
#landing-page-container #contact-section > div:first-of-type > div h3 {
  font-size: 4.8rem;
}
#landing-page-container #contact-section > div:first-of-type > div h4 {
  font-size: 3.2rem;
}
#landing-page-container #contact-section > form {
  width: 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 0 3.2rem;
  gap: 3.2rem;
}
#landing-page-container #contact-section > form > div:first-of-type {
  display: flex;
  justify-content: space-between;
  gap: 3.2rem;
  width: 100%;
}
#landing-page-container #contact-section > form div {
  position: relative;
  width: 100%;
}
#landing-page-container #contact-section > form label {
  position: absolute;
  top: -1.6rem;
  left: 1.6rem;
  background-color: #FFFFFF;
  padding: 0 0.8rem;
  color: #1059c8;
}
#landing-page-container #contact-section > form input[type=text], #landing-page-container #contact-section > form input[type=email] {
  width: 100%;
  height: 5rem;
}
#landing-page-container #contact-section > form input[type=text], #landing-page-container #contact-section > form input[type=email],
#landing-page-container #contact-section > form textarea {
  border-radius: 2.4rem;
  outline: 2px solid #D9D9D9;
  transition: all 0.3s ease;
  padding: 0.8rem 1.6rem;
  border: none;
  font-size: 2rem;
}
#landing-page-container #contact-section > form input[type=text]:focus, #landing-page-container #contact-section > form input[type=text]:active, #landing-page-container #contact-section > form input[type=email]:focus, #landing-page-container #contact-section > form input[type=email]:active,
#landing-page-container #contact-section > form textarea:focus,
#landing-page-container #contact-section > form textarea:active,
#landing-page-container #contact-section > form .fulfilled {
  outline: 2px solid #1059c8 !important;
}
#landing-page-container #contact-section > form .error {
  outline: 2px solid red !important;
}
#landing-page-container #contact-section > form textarea {
  width: 100%;
  padding-top: 2.4rem;
}
#landing-page-container #contact-section > form input[type=submit] {
  border: none;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.75rem;
}
@media screen and (max-width: 767px) {
  #landing-page-container #contact-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5vh;
    margin: 10vw 0;
  }
  #landing-page-container #contact-section > div:first-of-type {
    width: 100%;
  }
  #landing-page-container #contact-section > div:first-of-type > div {
    width: 100%;
  }
  #landing-page-container #contact-section > div:first-of-type > div h3 {
    font-size: 2.4rem;
  }
  #landing-page-container #contact-section > div:first-of-type > div h4 {
    font-size: 1.6rem;
  }
  #landing-page-container #contact-section > div:first-of-type > svg {
    display: none;
  }
  #landing-page-container #contact-section > form {
    width: 90%;
    font-size: 1.6rem;
  }
  #landing-page-container #contact-section > form > div:first-of-type {
    flex-direction: column;
  }
}
#landing-page-container footer {
  width: 100vw;
  height: 200px;
  background: linear-gradient(0deg, #003584, #1059C8);
  padding: 1.6rem;
  display: flex;
}
#landing-page-container footer > div:first-of-type {
  max-width: 25vw;
  font-weight: bold;
  color: #FFFFFF;
  text-transform: uppercase;
  letter-spacing: 0.75rem;
  margin-right: 3.2rem;
}
#landing-page-container footer > div:first-of-type h3:first-of-type {
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
}
#landing-page-container footer > div:first-of-type h5:first-of-type {
  font-size: 1rem;
}
#landing-page-container footer ul {
  height: 90%;
}
#landing-page-container footer ul > li {
  text-transform: uppercase;
  list-style: none;
  font-size: 1.6rem;
  letter-spacing: 0.125rem;
  margin-bottom: 0.8rem;
}
#landing-page-container footer ul > li > a {
  text-decoration: none;
  color: #FFFFFF;
  transition: all 0.3s ease;
}
#landing-page-container footer ul > li > a:hover {
  color: #C0C0C0;
}
#landing-page-container footer > div:last-of-type {
  font-size: 1.6rem;
  color: #FFFFFF;
}
#landing-page-container footer > div:last-of-type a {
  color: #DDDDDD;
}
#landing-page-container footer > span {
  height: 90%;
  width: 1px;
  background-color: #F4F4F4;
  margin: 0 3.2rem;
}
#landing-page-container #scroll-section-anchor,
#landing-page-container #contact-section-anchor {
  position: absolute;
  top: -10vh;
  visibility: hidden;
}
#landing-page-container #jokra-cloud-section-anchor {
  position: absolute;
  top: 10vh;
  visibility: hidden;
}

