@import '../variables';
@import '../animations';
@import '../mixins';

#width-button {
  position: relative;
  top: 0;
  width: 50px;
  height: 50px;
  border: none;
  background-color: $main_white;
  box-shadow: 5px 5px 8px #cfcfcf, -5px -5px 5px #ffffff;
}

#toolbar-container {
  //border-bottom: 1px solid #aaa;
  //box-shadow: 0 5px 8px #dfdfdf;
  //width: calc(#{$sidebar_width_extended} * 2);

  //& > div {
  //  &:nth-of-type(3) {
  //    min-width: 300px;
  //  }
  //
  //  &:not(div:nth-of-type(3)) {
  //    width: calc((100% - 300px) / 3);
  //  }
  //}
}