@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes paper-plane-fly {
  10% {
    top: 3px;
    left: -3px;
  }
  100% {
    top: -40px;
    left: 35px;
  }
}
@keyframes exit-animation {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0;
  }
}
@keyframes selected-border {
  0% {
    border: 1px solid #1059c8;
  }
  50% {
    border: 2px solid #003584;
  }
  100% {
    border: 1px solid #1059c8;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes float-down {
  from {
    transform: translateY(-4vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ripple {
  from {
    opacity: 1;
    scale: 0;
  }
  to {
    opacity: 0;
    scale: 2;
  }
}
#companies-modal {
  width: 100%;
  max-width: 75vw;
}
#companies-modal .modal-content {
  width: 100%;
  min-width: 300px;
  max-width: 75vw;
  height: 100%;
  min-height: 200px;
  max-height: 500px;
}
#companies-modal .modal-content .modal-header > h3 {
  margin: 0;
}
#companies-modal .modal-content .modal-body {
  max-height: 100%;
}
#companies-modal .modal-content .modal-body > div {
  gap: 2rem;
  overflow: hidden;
  max-height: 60vh;
}
#companies-modal .modal-content .modal-body div > div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}
#companies-modal .modal-content .modal-body div > div:first-child form label {
  color: #1059c8;
  font-weight: bold;
}
#companies-modal .modal-content .modal-body div > div:first-child form input {
  margin-bottom: 1rem;
  border-radius: 20px;
}
#companies-modal .modal-content .modal-body div > div:first-child button {
  margin-top: 1rem;
  border-radius: 20px;
  width: 100%;
}
#companies-modal .modal-content .modal-body div > div:last-child {
  max-height: 100%;
  overflow-y: auto;
  margin-right: 1rem;
}
#companies-modal .modal-content .modal-body div > div:last-child > h4 {
  color: #1059c8;
}
#companies-modal .modal-content .modal-body div > div:last-child > table > tbody > tr:nth-of-type(odd) {
  background-color: rgba(44, 116, 224, 0.05);
}

