@import '../../variables';
@import '../../animations';
@import '../../mixins';

.file-row {
	width: 100%;
	transition: all 0.3s ease;
	color: $MAIN_TEXT_COLOR;
	margin-right: 1rem;

	&:hover {
		// background-color: #ececec;
		// color: $main_blue_lighter;
		cursor: pointer;
		border-radius: 20px 0 0 20px;
		box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 5px #ffffff;
	}

	& h5 {
		width: 100%;
		margin: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	//& h3, & h4, & h5 {
	& h5:not(h5:first-of-type) {
		text-align: center;
		max-width: 15%;
		color: dimgrey;
	}

	& h5:nth-of-type(1) {
		max-width: 50%;
	}

	.button-container {
		transition: all 0.3s ease;
	}
}

.selected {
	background-color: #105ac822;
}

.selected:hover {
	background-color: #105ac844;
}
