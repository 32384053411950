// Imports
@import '../variables';
@import '../animations';
@import '../mixins';

// Style
.folder {
  width: $card_length;
  height: $card_length;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $main_white;
  color: $main_blue_darker;
  border-radius: 15px;
  border: none;
  //box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 5px #ffffff;

  transition: all 0.3s ease-out;

  margin: 1rem;

  text-decoration: none;
  position: relative;

  &:hover {
    transform: scale(1.1);
    box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 5px #ffffff;
    cursor: pointer;
  }

  & svg {
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  & img {
    width: 64px;
    height: 64px;
    //filter: drop-shadow(0 0 6px #e0e0e0);
  }

  & .img-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 0 0 1px black;
  }

  .locked-folders {
    position: absolute;
    width: 24px;
    height: 24px;
    right: .75rem;
    bottom: 2.75rem;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, .25));
  }

}
