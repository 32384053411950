@import '../../variables';
@import '../../animations';
@import '../../mixins';

.folder-row {
	width: 100%;
	transition: all 0.3s ease;

	&:hover {
		color: $main_blue_lighter;
		border-radius: 20px 0 0 20px;
		box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 5px #ffffff;
	}

	& a {
		color: $MAIN_TEXT_COLOR;
		text-decoration: none;
		transition: all 0.3s ease;
		//position: relative;
		//padding-left: 40px;

		//& img {
		//  position: absolute;
		//  left: 0;
		//}

		//&:hover {
		//  color: $main_blue_lighter;
		//  background-color: #ececec;
		//}

		& h5 {
			width: 100%;
			margin: 0;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		& h5:not(h5:first-of-type) {
			text-align: center;
			max-width: 15%;
			color: dimgrey;
		}

		& h5:nth-of-type(1) {
			max-width: 50%;
		}
	}
}
