@import '../variables';
@import '../animations';
@import '../mixins';

#login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;

  & > div {
    width: 200vw;
    position: absolute;
    transition: left 500ms ease;
  }

  & > div > div {
    width: 100vw;
    height: 100vh;
    display: flex;

    @media screen and (max-width: 992px) {
      justify-content: center;
      align-items: center;
    }

    &:last-of-type {
      position: absolute;
      left: 100vw;
      top: 0;
    }
  }

  & > div > div {
    //& > section:first-of-type,
    //& > section:last-of-type {

    & > section.svg-section,
    & > section.form-section {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    //& > section:first-of-type {
    & > section.svg-section {
      padding: 3.2rem;
      background: linear-gradient(90deg, $main_blue_darker, $main_blue_lighter);

      & > div:first-of-type {
        color: white;
        text-align: center;

        h1 {
          font-size: 4.8rem;
          font-weight: bold;
          margin-bottom: 3.2rem;
        }

        h3 {
          font-size: 1.6rem;
        }
      }

      & > svg {
        width: 100%;
        height: auto;
      }
    }

    //& > section:last-of-type {
    & > section.form-section {
      gap: 6.4rem;
      background-color: #FFFFFF;

      & > h1 {
        font-size: 3.2rem;
      }

      & > form {
        width: 75%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        padding: 0 3.2rem;
        gap: 3.2rem;

        & > h3 {
          text-align: center;
        }

        & > div:first-of-type {
          display: flex;
          justify-content: space-between;
          gap: 3.2rem;
          width: 100%;
        }

        & > div:not(div.switch-container) {
          position: relative;
          width: 100%;
        }

        & > div:last-of-type {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        & > div:not(div:last-of-type) > label {
          position: absolute;
          top: -1.6rem;
          left: 1.6rem;
          background-color: #FFFFFF;
          padding: 0 .8rem;
          color: #1059c8;
        }

        & input[type="password"],
        & input[type="email"] {
          width: 100%;
          height: 5rem;
        }

        & input[type="password"],
        & input[type="email"] {
          border-radius: 2.4rem;
          outline: 2px solid #D9D9D9;
          transition: all .3s ease;
          padding: .8rem 1.6rem;
          border: none;
          font-size: 2rem;
        }

        & input[type="password"]:focus,
        & input[type="password"]:active,
        & input[type="email"]:focus,
        & input[type="email"]:active,
        .fulfilled {
          outline: 2px solid #1059c8 !important;
        }

        .error {
          outline: 2px solid red !important;
        }

        & input[type="checkbox"] {
          opacity: 0;
          position: absolute;
          left: -9999px;
          top: -9999px;

          & + label {
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          & + label::before {
            content: "";
            width: 4.8rem;
            height: 2.4rem;
            border-radius: 2.4rem;
            margin-right: 1.6rem;
            background: #D9D9D9;
          }

          & + label::after {
            content: "";
            position: absolute;
            left: .4rem;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 2.4rem;
            background: #FFFFFF;
            transition: .3s ease;
          }

          &:active + label::after {
            width: 2.4rem;
          }

          &:checked:active + label::after {
            left: 2rem;
          }

          &:checked + label::before {
            background: linear-gradient(90deg, $main_blue_darker, $main_blue_lighter);
          }

          &:checked + label::after {
            content: "";
            left: 2.8rem;
            //left: calc(100% - .4rem);
          }
        }

        input[type="submit"] {
          border: none;
          width: 100%;
          text-transform: uppercase;
          letter-spacing: .75rem;
        }

        & button {
          background-color: transparent;
          border: none;
          color: $main_blue_lighter;
          font-size: 1.6rem;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: .125rem;
            left: 50%;
            top: 100%;
            background-color: $main_blue_lighter;
            transition: all 300ms ease;
          }

          &:hover {
            color: #0d6efd;
          }

          &:hover::before {
            left: 0;
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      & > section.svg-section {
        display: none;
      }

      & > section.form-section {
        width: 100%;
        height: 60%;

        & > h1 {
          font-size: 2.4rem;
        }

        & > form {
          width: 100%;

          & * {
            font-size: 1.6rem;
          }
        }
      }
    }

    @media screen and (max-width: 992px) {
      & > section.svg-section {
        display: none;
      }

      & > section.form-section {
        width: 100%;
        height: 60%;
      }
    }

    .alert {
      width: 100%;
      font-size: 1.6rem;
      border-radius: 2.4rem;
    }

    &:first-of-type > section:first-of-type,
    &:first-of-type > section:last-of-type form > div,
    &:first-of-type > section:last-of-type form > input[type="submit"] {
      opacity: 0;
    }
  }
}

//#form-container {
//  background-color: $main_white;
//  border-radius: 20px;
//  box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
//  border: none;
//
//  input[type] {
//    box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 5px #ffffff;
//    border-radius: 20px;
//    border-color: transparent;
//    transition: all 0.3s ease;
//
//    &:focus {
//      border: 1px solid $main_blue_lighter;
//    }
//  }
//
//  label {
//    font-weight: bold;
//    color: $main_blue_darker;
//  }
//
//  button {
//    border-radius: 20px;
//  }
//}
