@import '../variables';
@import '../animations';
@import '../mixins';

#searchbar-container {
  //display: flex;
  //justify-content: center;
  position: relative;
  //overflow: hidden;
  //min-width: 300px;

  & > div {
    display: flex;
    justify-content: center;

    .icon-container {
      position: absolute;
      right: 1rem;
      top: 25%;
      color: $main_blue_lighter;
    }
  }

  input[type="text"] {
    height: 100%;
    width: 200px;
    border-radius: 25px;
    transition: all 0.3s ease;
    //transition: all 0.5s ease;
    //box-shadow: inset 5px 5px 8px #cfcfcf, inset -5px -5px 5px #ffffff;
    //background-color: #eef5ff;
    //border: 1px solid black;


    &:focus {
      //width: 300px;
      border: 1px solid transparent;
      background-color: transparent;
    }
  }

  .stretchable {
    &:focus {
      width: 300px;
      //border: 1px solid $main_blue_lighter;
    }
  }
}

