// Import fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

// BASIC CONFIG . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

// COLORS . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
$main_blue_lightest: #eee;
$main_blue_lighter: #1059c8;
$main_blue_darker: #003584;
$table_row_dimmed: rgba(44, 116, 224, 0.05);
$main_white: #f5f5f5;
$main_gradient: linear-gradient(90deg, #{$main_blue_lighter}, #{$main_blue_darker});
$MAIN_TEXT_COLOR: #222222;
$DIMMED_TEXT_COLOR: #777;

// SIZES AND FONT SIZES . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
// Sidebar
$sidebar_width_closed: 5rem;
$sidebar_width_extended: 21rem;
$SidebarList_padding_left: 1rem;
$span_width: 100%;
$span_gradient_width: calc(#{$span_width} + #{$SidebarList_padding_left});

// Main content
$main_content_width: calc(100vw - #{$sidebar_width_closed});

// File / Folder card
$card_length: 100px;

// Loader
$loader-circle-size: 17px;
$loader-container-size: 100px;

// TIMING . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .

// Positioned last because of variables
#root {
  display: flex;
  background-color: $main_white;
  min-width: 100vw;
}

#main {
  width: $main_content_width;
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

// Custom classes
.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  //transition: all .3s ease;

  &:hover {
    background: linear-gradient(180deg, #1059C8, #2F7AED);
    //background-position: 0 -100%;
  }
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
