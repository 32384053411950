@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes paper-plane-fly {
  10% {
    top: 3px;
    left: -3px;
  }
  100% {
    top: -40px;
    left: 35px;
  }
}
@keyframes exit-animation {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0;
  }
}
@keyframes selected-border {
  0% {
    border: 1px solid #1059c8;
  }
  50% {
    border: 2px solid #003584;
  }
  100% {
    border: 1px solid #1059c8;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes float-down {
  from {
    transform: translateY(-4vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ripple {
  from {
    opacity: 1;
    scale: 0;
  }
  to {
    opacity: 0;
    scale: 2;
  }
}
#login-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: #FFFFFF;
  position: relative;
  overflow: hidden;
}
#login-page > div {
  width: 200vw;
  position: absolute;
  transition: left 500ms ease;
}
#login-page > div > div {
  width: 100vw;
  height: 100vh;
  display: flex;
}
@media screen and (max-width: 992px) {
  #login-page > div > div {
    justify-content: center;
    align-items: center;
  }
}
#login-page > div > div:last-of-type {
  position: absolute;
  left: 100vw;
  top: 0;
}
#login-page > div > div > section.svg-section, #login-page > div > div > section.form-section {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#login-page > div > div > section.svg-section {
  padding: 3.2rem;
  background: linear-gradient(90deg, #003584, #1059c8);
}
#login-page > div > div > section.svg-section > div:first-of-type {
  color: white;
  text-align: center;
}
#login-page > div > div > section.svg-section > div:first-of-type h1 {
  font-size: 4.8rem;
  font-weight: bold;
  margin-bottom: 3.2rem;
}
#login-page > div > div > section.svg-section > div:first-of-type h3 {
  font-size: 1.6rem;
}
#login-page > div > div > section.svg-section > svg {
  width: 100%;
  height: auto;
}
#login-page > div > div > section.form-section {
  gap: 6.4rem;
  background-color: #FFFFFF;
}
#login-page > div > div > section.form-section > h1 {
  font-size: 3.2rem;
}
#login-page > div > div > section.form-section > form {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 0 3.2rem;
  gap: 3.2rem;
}
#login-page > div > div > section.form-section > form > h3 {
  text-align: center;
}
#login-page > div > div > section.form-section > form > div:first-of-type {
  display: flex;
  justify-content: space-between;
  gap: 3.2rem;
  width: 100%;
}
#login-page > div > div > section.form-section > form > div:not(div.switch-container) {
  position: relative;
  width: 100%;
}
#login-page > div > div > section.form-section > form > div:last-of-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#login-page > div > div > section.form-section > form > div:not(div:last-of-type) > label {
  position: absolute;
  top: -1.6rem;
  left: 1.6rem;
  background-color: #FFFFFF;
  padding: 0 0.8rem;
  color: #1059c8;
}
#login-page > div > div > section.form-section > form input[type=password], #login-page > div > div > section.form-section > form input[type=email] {
  width: 100%;
  height: 5rem;
}
#login-page > div > div > section.form-section > form input[type=password], #login-page > div > div > section.form-section > form input[type=email] {
  border-radius: 2.4rem;
  outline: 2px solid #D9D9D9;
  transition: all 0.3s ease;
  padding: 0.8rem 1.6rem;
  border: none;
  font-size: 2rem;
}
#login-page > div > div > section.form-section > form input[type=password]:focus, #login-page > div > div > section.form-section > form input[type=password]:active, #login-page > div > div > section.form-section > form input[type=email]:focus, #login-page > div > div > section.form-section > form input[type=email]:active,
#login-page > div > div > section.form-section > form .fulfilled {
  outline: 2px solid #1059c8 !important;
}
#login-page > div > div > section.form-section > form .error {
  outline: 2px solid red !important;
}
#login-page > div > div > section.form-section > form input[type=checkbox] {
  opacity: 0;
  position: absolute;
  left: -9999px;
  top: -9999px;
}
#login-page > div > div > section.form-section > form input[type=checkbox] + label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
#login-page > div > div > section.form-section > form input[type=checkbox] + label::before {
  content: "";
  width: 4.8rem;
  height: 2.4rem;
  border-radius: 2.4rem;
  margin-right: 1.6rem;
  background: #D9D9D9;
}
#login-page > div > div > section.form-section > form input[type=checkbox] + label::after {
  content: "";
  position: absolute;
  left: 0.4rem;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 2.4rem;
  background: #FFFFFF;
  transition: 0.3s ease;
}
#login-page > div > div > section.form-section > form input[type=checkbox]:active + label::after {
  width: 2.4rem;
}
#login-page > div > div > section.form-section > form input[type=checkbox]:checked:active + label::after {
  left: 2rem;
}
#login-page > div > div > section.form-section > form input[type=checkbox]:checked + label::before {
  background: linear-gradient(90deg, #003584, #1059c8);
}
#login-page > div > div > section.form-section > form input[type=checkbox]:checked + label::after {
  content: "";
  left: 2.8rem;
}
#login-page > div > div > section.form-section > form input[type=submit] {
  border: none;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.75rem;
}
#login-page > div > div > section.form-section > form button {
  background-color: transparent;
  border: none;
  color: #1059c8;
  font-size: 1.6rem;
  position: relative;
}
#login-page > div > div > section.form-section > form button::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0.125rem;
  left: 50%;
  top: 100%;
  background-color: #1059c8;
  transition: all 300ms ease;
}
#login-page > div > div > section.form-section > form button:hover {
  color: #0d6efd;
}
#login-page > div > div > section.form-section > form button:hover::before {
  left: 0;
  width: 100%;
}
@media screen and (max-width: 767px) {
  #login-page > div > div > section.svg-section {
    display: none;
  }
  #login-page > div > div > section.form-section {
    width: 100%;
    height: 60%;
  }
  #login-page > div > div > section.form-section > h1 {
    font-size: 2.4rem;
  }
  #login-page > div > div > section.form-section > form {
    width: 100%;
  }
  #login-page > div > div > section.form-section > form * {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 992px) {
  #login-page > div > div > section.svg-section {
    display: none;
  }
  #login-page > div > div > section.form-section {
    width: 100%;
    height: 60%;
  }
}
#login-page > div > div .alert {
  width: 100%;
  font-size: 1.6rem;
  border-radius: 2.4rem;
}
#login-page > div > div:first-of-type > section:first-of-type, #login-page > div > div:first-of-type > section:last-of-type form > div, #login-page > div > div:first-of-type > section:last-of-type form > input[type=submit] {
  opacity: 0;
}

