@import '../variables';
@import '../animations';
@import '../mixins';

#landing-page-container {
  background-color: #FFFFFF;
  padding-top: 10vh;
  position: relative;
  width: 100vw;

  @media screen and (max-width: 767px) {

  }

  @media screen and (max-width: 992px) {

  }

  @media screen and (min-width: 1200px) {

  }

  header {
    width: 100%;
    height: 10vh;
    top: 0;
    background-color: #FFFFFF;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3.2rem;
    box-shadow: 0 0 5rem 0 #FFFFFF;
    z-index: 100;

    & > .logo {
      font-size: 2.4rem;
      letter-spacing: 0.5rem;
      font-weight: bold;
      color: #0a53be;
    }

    & > ul {
      display: flex;
      align-items: center;
      gap: 2.4rem;
      height: 100%;

      & > li {
        text-transform: uppercase;
        list-style: none;
        font-size: 1.6rem;
        letter-spacing: 0.25rem;
        transition: all .3s ease;
        position: relative;
        opacity: 0;

        & > a {
          text-decoration: none;
          color: #000000;

          &:hover {
            color: #1059c8;
          }
        }

        &:not(&:last-of-type)::before {
          content: "";
          position: absolute;
          height: .125em;
          top: 100%;
          left: 50%;
          width: 0;
          transition: all .3s ease;
        }

        &:hover:not(&:last-of-type) {
          cursor: pointer;
          color: #1059c8;

          &::before {
            width: 110%;
            left: -5%;
            background-color: #1059c8;
          }
        }


        &:last-of-type > a {
          color: #FFFFFF;
        }
      }
    }

    @media screen and (max-width: 767px) {
      // Hamburger menu

      & > ul {
        margin: 0 5vw;

        & > li {
          display: none;
        }
      }
    }
  }


  #progress-bar {
    display: block;
    height: .33vh;
    background: linear-gradient(90deg, $main_blue_darker, $main_blue_lighter);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  #progress-bar-placeholder {
    display: block;
    height: .33vh;
    width: 100vw;
    background: #E4E4E4;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  #hero-section {
    width: 100%;
    height: 75vh;
    background-color: #1059C819;
    border-bottom-right-radius: 100rem;
    position: relative;
    display: flex;
    align-items: center;

    .text-section {
      display: flex;
      flex-direction: column;
      padding-left: 6.4rem;
      width: 75rem;

      h1 {
        font-size: 7.2rem;
        font-weight: bold;
        letter-spacing: .2em;
        margin-bottom: 3.2rem;
        line-height: 5.6rem;
        opacity: 0;
      }

      h4 {
        font-size: 2rem;
        margin-bottom: 3.2rem;
        opacity: 0;
      }

      a {
        font-size: 1.6rem;
        text-transform: uppercase;
        letter-spacing: .25rem;
        width: min-content;
        color: #FFFFFF;
        padding: 1rem 3.2rem;
        opacity: 0;
      }
    }

    #leader-illustration {
      position: absolute;
      width: 40vw;
      right: 6.4rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all .3s ease;

      & > g {
        opacity: 0;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;

      & > div:first-of-type {
        width: 80%;
        padding: 5vh 0;
        height: 90%;
        justify-content: space-between;

        & > h1 {
          font-size: 3.2rem;
          text-align: center;
        }

        & > h4 {
          display: none;
        }
      }

      & > #leader-illustration {
        left: 75%;
        top: 60%;
        transform: translate(-50%, -50%);
      }
    }
  }

  #scroll-section {
    position: relative;
    width: 100vw;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 7vw;
    margin: 15vh auto;

    & > div {
      width: 20vw;

      & > h3 {
        font-size: 3.2rem;
        text-align: center;
      }

      & > svg {
        height: 400px;
        width: 100%;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 90%;
      gap: 5vh;

      & > div {
        width: 100%;

        & > h3 {
          font-size: 1.6rem;
          text-align: center;
        }

        & > svg {
          height: 200px;
          width: 100%;
        }
      }
    }
  }

  #client-section,
  #slovenia-section {
    width: 100vw;
    height: 75vh;
    background-color: #1059C819;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5vw;
    gap: 5vw;

    & > div {
      max-width: 60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > h3 {
        font-size: 4.8rem;
        font-weight: 600;
        margin-bottom: 3.2rem;
      }

      & > p {
        font-weight: 100;
        font-size: 2.4rem;
      }
    }

    & > svg {
      width: 40vw;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      height: auto;

      & > div {
        text-align: center;

        & > h3 {
          font-size: 3.2rem;
        }

        & > p {
          font-size: 1.6rem;
        }
      }

      & > svg {
        height: 300px;
        width: 90%;
      }
    }
  }

  #client-section {
    border-top-left-radius: 100rem;
  }

  #slovenia-section {
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  #jokra-cloud-info-section {
    //padding: 6.4rem 0 0 6.4rem;
    padding: 5vw 0 0 5vw;
    background: linear-gradient(to bottom, #1059C819, #FFFFFF00);

    & > h1 {
      letter-spacing: 0.5rem;
      text-transform: uppercase;
      font-size: 6.4rem;
      font-weight: bold;
    }

    & > h3 {
      font-size: 2.4rem;
    }

    @media screen and (max-width: 767px) {
      padding: 3.2rem 0 0 3.2rem;

      & > h1 {
        font-size: 3.2rem;
      }

      & > h3 {
        font-size: 1.6rem;
      }
    }
  }

  #jokra-cloud-section {
    width: 100vw;
    height: 90vh;
    position: relative;
    margin-bottom: 20vh;

    & > div {
      background: linear-gradient(180deg, #003584, #1059C8);
      position: absolute;
      left: 50%;
      top: 5%;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      overflow: hidden;

      & > div:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 3.2rem;

        & > img {
          aspect-ratio: 1/1;
          width: 3.2rem;
          filter: invert(1);
          transition: filter .3s ease;

          &:hover {
            cursor: pointer;
            filter: invert(0.75);
          }

          &:first-of-type {
            rotate: 180deg;
          }
        }
      }

      & > div:last-of-type {
        text-align: center;
        color: #FFFFFF;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;

        & > div {
          position: absolute;
        }

        & h4 {
          font-size: 4.8rem;
          margin-bottom: 3.2rem;
        }

        & p {
          font-size: 1.6rem;
        }
      }

      & .carousel-info-wrapper > div {
        display: none;
      }

      & .carousel-info-wrapper > div:first-of-type {
        display: block;
      }

      & .carousel {
        position: relative;
        max-width: 1200px;
        width: 100%;
        height: 500px;
        padding: 5vh 0;

        & > div {
          aspect-ratio: 16 / 9;
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          box-shadow: 0 0 10rem 0 rgba(0, 0, 0, .25);
          transition: all 500ms ease-in-out;

          & > img {
            width: 100%;
            transition: all 500ms ease-in-out;
          }

          //&:first-of-type {
          &.carousel-item-1 {
            left: 30%;
            height: 350px;

            & > img {
              opacity: 0.75;
            }
          }

          //&:nth-of-type(2) {
          &.carousel-item-2 {
            left: 50%;
            z-index: 3;
            height: 400px;
            opacity: 1;
          }

          //&:nth-of-type(3) {
          //  left: 50%;
          //  z-index: 5;
          //}
          //
          //&:nth-of-type(4) {
          //  left: 60%;
          //  z-index: 4;
          //  height: 330px;
          //  opacity: 0.9;
          //}

          //&:last-of-type {
          &.carousel-item-3 {
            left: 70%;
            height: 350px;

            & > img {
              opacity: 0.75;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 40vh;
    }
  }


  #contact-section {
    width: 100vw;
    height: 90vh;
    display: flex;
    position: relative;

    & > div:first-of-type {
      width: 50vw;
      padding: 0 2.5vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & > svg {
        height: 70%;
        width: 100%;
      }

      & > div {
        text-align: center;

        & h3 {
          font-size: 4.8rem;
        }

        & h4 {
          font-size: 3.2rem;
        }
      }
    }

    & > form {
      width: 60rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      padding: 0 3.2rem;
      gap: 3.2rem;

      & > div:first-of-type {
        display: flex;
        justify-content: space-between;
        gap: 3.2rem;
        width: 100%;
      }

      & div {
        position: relative;
        width: 100%;
      }

      & label {
        position: absolute;
        top: -1.6rem;
        left: 1.6rem;
        background-color: #FFFFFF;
        padding: 0 .8rem;
        color: #1059c8;
      }

      & input[type="text"],
      & input[type="email"] {
        width: 100%;
        height: 5rem;
      }

      & input[type="text"],
      & input[type="email"],
      textarea {
        border-radius: 2.4rem;
        outline: 2px solid #D9D9D9;
        transition: all .3s ease;
        padding: .8rem 1.6rem;
        border: none;
        font-size: 2rem;
      }

      & input[type="text"]:focus,
      & input[type="text"]:active,
      & input[type="email"]:focus,
      & input[type="email"]:active,
      textarea:focus,
      textarea:active,
      .fulfilled {
        outline: 2px solid #1059c8 !important;
      }

      .error {
        outline: 2px solid red !important;
      }

      textarea {
        width: 100%;
        padding-top: 2.4rem;
      }

      input[type="submit"] {
        border: none;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: .75rem;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5vh;
      margin: 10vw 0;

      & > div:first-of-type {
        width: 100%;

        & > div {
          width: 100%;

          & h3 {
            font-size: 2.4rem;
          }

          & h4 {
            font-size: 1.6rem;
          }
        }

        & > svg {
          display: none;
        }
      }

      & > form {
        width: 90%;
        font-size: 1.6rem;

        & > div:first-of-type {
          flex-direction: column;
        }
      }
    }
  }

  footer {
    width: 100vw;
    height: 200px;
    background: linear-gradient(0deg, #003584, #1059C8);
    padding: 1.6rem;
    display: flex;

    & > div:first-of-type {
      max-width: 25vw;
      font-weight: bold;
      color: #FFFFFF;
      text-transform: uppercase;
      letter-spacing: .75rem;
      margin-right: 3.2rem;

      h3:first-of-type {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
      }

      h5:first-of-type {
        font-size: 1rem;
      }
    }

    & ul {
      height: 90%;

      & > li {
        text-transform: uppercase;
        list-style: none;
        font-size: 1.6rem;
        letter-spacing: 0.125rem;
        margin-bottom: .8rem;

        & > a {
          text-decoration: none;
          color: #FFFFFF;
          transition: all .3s ease;

          &:hover {
            color: #C0C0C0;
          }
        }
      }
    }

    & > div:last-of-type {
      font-size: 1.6rem;
      color: #FFFFFF;

      & a {
        color: #DDDDDD;
      }
    }

    & > span {
      height: 90%;
      width: 1px;
      background-color: #F4F4F4;
      margin: 0 3.2rem;
    }

    @media screen and (max-width: 767px) {

    }
  }

  #scroll-section-anchor,
  #contact-section-anchor {
    position: absolute;
    top: -10vh;
    visibility: hidden;
  }

  #jokra-cloud-section-anchor {
    position: absolute;
    top: 10vh;
    visibility: hidden;
  }
}