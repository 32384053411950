@import '../../variables';
@import '../../animations';
@import '../../mixins';


.main-container {
  width: 100%;
  box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
  border-radius: 20px;
  padding: 2rem;

  & form {
    button {
      margin-top: 1rem;
      font-weight: bold;
    }
  }

  .table-container {
    overflow: hidden;
    padding-top: 1rem;
    border-radius: 20px;
    box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 8px #ffffff;
    width: 100%;
  }
}