@import '../variables';
@import '../animations';
@import '../mixins';

main {
	width: 100%;

	#form-container {
		width: 70%;
		max-width: 700px;
		min-width: 400px;
		border-radius: 20px;
		box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;

		input[type],
		textarea,
		select {
			box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 5px #ffffff;
			border-radius: 20px;
			border-color: transparent;
			//border: none;
			transition: all 0.3s ease;

			&:focus {
				border: 1px solid $main_blue_lighter;
			}
		}

		select {
			border: none;
		}

		input[type]:not(#contact-subject input[type='text']) {
			color: grey;
		}

		label {
			font-weight: bold;
			color: $main_blue_darker;
		}

		textarea {
			border-radius: 10px;
		}

		#sendIcon[animate='1'] {
			animation: paper-plane-fly 1s 1 ease;
		}

		button {
			border-radius: 20px;
			width: 100%;
			max-width: 300px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
