@import '../variables';
@import '../animations';
@import '../mixins';

.company-selection-container {
  display: flex;

  & > button {
    text-align: center;

    & > div,
    & > img {
      width: 64px;
      aspect-ratio: 1/1
    }

    & > div {
      display: none;
      border: 1px dashed gray;
      align-items: center;
      justify-content: center;

      & > p {
        width: 100%;
        text-wrap: wrap;
        color: grey;
      }
    }
  }
}