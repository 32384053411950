// Imports
@import '../variables';
@import '../animations';
@import '../mixins';

// Card style
.file {
  width: $card_length;
  height: $card_length;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $main_white;
  color: $main_blue_darker;
  border-radius: 15px;
  border: 1px solid transparent;
  // box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 5px #ffffff;

  transition: all 0.3s ease;

  margin: 1rem;

  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  & svg {
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  & img {
    //width: 64px;
    width: 48px;
    //height: 48px;
    //filter: drop-shadow(0 0 6px #e0e0e0);
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 5px #ffffff;
  }

  & p {
    width: 90%;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    margin: 0;
  }
}

#file-modal {
  .modal-content {
    border: none;
    border-radius: 20px !important;

    & a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: inherit;
      }
    }

    & .modal-body.file-modal div {
      width: 50%;

      & img,
      p {
        width: 100%;
        max-height: 128px;
        max-width: 128px;
      }

      & img {
        filter: drop-shadow(0 0 3px #aaa);
      }
    }

    & h5:not(h5:first-of-type),
    h4 {
      color: $main_blue_lighter;
    }

    .file-modal button:first-of-type:not(.btn-close) {
      border-bottom-left-radius: 10px;
    }

    button:last-of-type {
      border-bottom-right-radius: 10px;
    }
  }
}

#selected {
  border: 1.5px dashed $main_blue_lighter;
  //animation: selected-border 1s ease infinite;
}
