@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", Helvetica, sans-serif;
  /* color: #fff; */
}

:root {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  background-color: #f5f5f5;
  min-width: 100vw;
}

#main {
  width: calc(100vw - 5rem);
  padding: 1rem 0 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
}

.alert {
  border-radius: 20px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-right-bottom-button {
  border-bottom-right-radius: 1rem !important;
}

.table-delete-button {
  border-radius: 6px !important;
}

.primary-button {
  padding: 1rem 1.6rem;
  border-radius: 2rem;
  text-decoration: none;
  color: #FFFFFF;
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
}
.primary-button:hover {
  background: linear-gradient(180deg, #1059C8, #2F7AED);
}

span.important-text {
  font-weight: bold;
  background: linear-gradient(180deg, #003584, #1059C8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes paper-plane-fly {
  10% {
    top: 3px;
    left: -3px;
  }
  100% {
    top: -40px;
    left: 35px;
  }
}
@keyframes exit-animation {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0;
  }
}
@keyframes selected-border {
  0% {
    border: 1px solid #1059c8;
  }
  50% {
    border: 2px solid #003584;
  }
  100% {
    border: 1px solid #1059c8;
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes float-down {
  from {
    transform: translateY(-4vh);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes ripple {
  from {
    opacity: 1;
    scale: 0;
  }
  to {
    opacity: 0;
    scale: 2;
  }
}
#not-found-container,
#forbidden-access-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#not-found-container > div:first-of-type,
#forbidden-access-container > div:first-of-type {
  position: absolute;
  font-size: 50rem;
  font-weight: bold;
  color: #f5f5f5;
  z-index: 0;
  text-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
  opacity: 0.7;
}
#not-found-container > div:not(div:first-of-type),
#forbidden-access-container > div:not(div:first-of-type) {
  z-index: 10;
  font-size: 4rem;
}
#not-found-container button,
#forbidden-access-container button {
  padding: 0.5rem 2rem;
  font-size: 2rem;
  border-radius: 20px;
  z-index: 10;
  background: linear-gradient(0deg, #003584, #1059c8);
  transition: all 0.3s ease;
  border: none;
  box-shadow: none;
}
#not-found-container button:hover,
#forbidden-access-container button:hover {
  transform: scale(1.2);
}
#not-found-container button:active,
#forbidden-access-container button:active {
  filter: brightness(1.2);
}

