@import '../../variables';
@import '../../animations';
@import '../../mixins';

#add-file-modal {
	min-width: 350px;
	max-width: 700px;
	min-height: 300px;
	max-height: 700px;

	.dropzone {
		min-height: 200px;
		max-height: 700px;
		color: dimgrey;
		border-radius: 20px;
		cursor: pointer;

		border: 1.5px dashed green;
		background-color: #e4f7e4;

		//border: 1.5px dashed dimgray;
		//background-color: #eeeeee;

		&:hover {
			background-color: #cdfacd;
		}
	}

	.dragover {
		animation: bounce 1.5s infinite ease-out;
	}

	button,
	.dropzone {
		transition: all 0.3s ease;
	}

	.delete-file {
		//display: none;
		transition: all 0.3s ease;
		//opacity: 0;
		//pointer-events: none;
		border-radius: 0.5rem;
	}

	.show {
		//display: block;
		opacity: 1;
		pointer-events: auto;
	}
}
