@import '../../variables';
@import '../../animations';
@import '../../mixins';

#quick-mail-editor {
  overflow: hidden;

  header {
    & > div {
      display: flex;
      padding: 1rem 1.5rem;
      border-radius: 20px;
      box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
      gap: 1rem;
      position: relative;

      &:first-of-type button,
      &:nth-of-type(7) button {
        border-radius: 20px;
      }

      &:last-of-type {
        & button:first-of-type {
          border-radius: 20px 5px 5px 20px;
        }

        & button:last-of-type {
          border-radius: 5px 20px 20px 5px;
        }
      }

      & h6 {
        position: absolute;
        top: -1.75rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  #editor-form-container {
    height: 100%;
    width: 100%;


    form {
      height: 100%;
      box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
      border-radius: 20px;
      //padding: 1rem;

      //& > div:last-of-type {
      //  height: 100%;
      //}

      .form-label {
        color: $main_blue_darker;
        font-weight: bold;
        margin-bottom: .5rem;
      }

      .form-control,
      .form-select {
        box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 5px #ffffff;
        border-radius: 20px;
        border-color: transparent;

        &:focus {
          box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 5px #ffffff;
          border: 1px solid $main_blue_lighter;
        }
      }

      textarea {
        height: calc(100% - 2.5rem);
      }

      & > div {
        width: 100%;
        padding: 1rem;

        &:nth-of-type(1),
        &:nth-of-type(2) {
          margin-bottom: 1rem;
        }
      }

      div:nth-of-type(1),
      div:nth-of-type(2) {
        height: 10%;
      }

      div:nth-of-type(3) {
        height: 80%;
      }

      #content-container {
        box-shadow: inset 5px 5px 8px #dfdfdf, inset -5px -5px 8px #ffffff;
        transition: all 0.125s ease;
        border-radius: 20px;
        border: 0 solid transparent;
        outline: none;
        overflow: auto;

        &:focus {
          border: 1px solid $main_blue_lighter;
        }
      }
    }
  }
}

.modal-content {
  h4 {
    color: black;
  }
}
