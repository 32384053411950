@import "../../variables";
@import "../../animations";
@import "../../mixins";

#users-modal {
  .modal-header h3 {
    margin: 0;
  }

  .modal-body {
    form {
      & > div {
        & > label {
          color: $main_blue_lighter;
          font-weight: bold;
        }

        & > input[type="text"] {
          border-radius: 20px;
          margin-bottom: 1rem;

          //&:focus {
          //  border: 1px solid $main_blue_darker;
          //}
        }
      }

      & > button {
        border-radius: 20px;
      }

      .no-highlight {
        font-weight: normal;
        color: inherit;
      }

      .highlight {
        color: $main_blue_lighter;
        font-weight: bold;
      }
    }
  }
}

#users-modal-permissions {
  h5 {
    cursor: pointer;
  }

  input[type='checkbox'] {
    width: 0;
    height: 0;
    visibility: hidden;
  }

  & label {
    cursor: pointer;
    width: 20px;
    height: 10px;
    background-color: grey;
    display: block;
    border-radius: 10px;
    position: relative;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-right: 1.5rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 8px;
      height: 8px;
      background-color: $main_white;
      border-radius: 9px;
      transition: 0.3s;
    }

    &:active::after {
      width: 13px;
    }
  }

  input[type='checkbox']:checked + label {
    background-color: $main_blue_darker;
    background: $main_gradient;
  }

  input[type='checkbox']:checked + label::after {
    left: calc(100% - 1px);
    //right: .5px;
    transform: translateX(-100%);
  }
}