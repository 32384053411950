@import '../variables';
@import '../animations';
@import '../mixins';

#help-container {
	overflow-y: auto;

	h1,
	h4 {
		color: $main_blue_lighter;
	}

	.nav-tabs {
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);

		.nav-item {
			&:first-of-type {
				margin-left: 1rem;
			}

			.nav-link {
				color: $main_blue_lighter;
				background: $main_white;
				font-size: 1.6rem;
				font-weight: bold;
				border-bottom: 1px solid rgba(0, 0, 0, 0.125);
				border-radius: 7px 7px 0 0;
			}

			.active {
				border-bottom: none;
				border-left: 1px solid rgba(0, 0, 0, 0.125);
				border-right: 1px solid rgba(0, 0, 0, 0.125);
				border-top: 1px solid rgba(0, 0, 0, 0.125);
				transform: translateY(1px);
			}
		}
	}

	.accordion:not(#sub-accordion) {
		width: 100%;
		max-width: 700px;
		min-width: 300px;
		//max-height: 500px;
		margin-top: 5rem;
		box-shadow: 5px 5px 8px #dfdfdf, -5px -5px 8px #ffffff;
		border-radius: 20px;
		//overflow-y: auto;

		.accordion-item,
		.accordion-header,
		.accordion-button {
			background-color: $main_white;
		}

		.accordion-button:focus {
			box-shadow: none;
			border-color: transparent;
		}
	}
}
