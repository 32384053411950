// Imports
@import '../variables';
@import '../animations';
@import '../mixins';

#main {
	width: $main_content_width;
	//padding: 1rem 0 0 1rem;
	padding: 1rem;
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 100vh;
}

// FILE CARD STYLE . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .

// FOLDER CARD STYLE . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
