@import '../variables';
@import '../animations';
@import '../mixins';

#main-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $main_white;
}

#loader-container {
  width: $loader-container-size;
  height: $loader-container-size;
  position: relative;
  margin-bottom: 50px;

  .loader-circle-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    animation: loader-animation 2s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: 0s;

      & div {
        width: $loader-circle-size;
        height: $loader-circle-size;
      }
    }

    &:nth-child(2) {
      animation-delay: 0.08s;

      & div {
        width: calc(#{ $loader-circle-size } - 2px);
        height: calc(#{ $loader-circle-size } - 2px);
      }
    }

    &:nth-child(3) {
      animation-delay: 0.16s;

      & div {
        width: calc(#{ $loader-circle-size } - 4px);
        height: calc(#{ $loader-circle-size } - 4px);
      }
    }

    &:nth-child(4) {
      animation-delay: 0.24s;

      & div {
        width: calc(#{ $loader-circle-size } - 6px);
        height: calc(#{ $loader-circle-size } - 6px);
      }
    }

    &:nth-child(5) {
      animation-delay: 0.32s;

      & div {
        width: calc(#{ $loader-circle-size } - 8px);
        height: calc(#{ $loader-circle-size } - 8px);
      }
    }

    .loader-circle {
      border-radius: 50%;
      //background-color: $main_blue_darker;
      background: linear-gradient(to bottom, $main_blue_darker, $main_blue_lighter);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}