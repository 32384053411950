@import "../../variables";
@import "../../animations";
@import "../../mixins";

#companies-modal {
  width: 100%;
  max-width: 75vw;

  .modal-content {
    width: 100%;
    min-width: 300px;
    max-width: 75vw;

    height: 100%;
    min-height: 200px;
    max-height: 500px;


    .modal-header > h3 {
      margin: 0;
    }

    .modal-body {
      max-height: 100%;

      & > div {
        gap: 2rem;
        overflow: hidden;
        max-height: 60vh;
      }

      & div > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 1rem;

        form label {
          color: $main_blue_lighter;
          font-weight: bold;
        }

        form input {
          margin-bottom: 1rem;
          border-radius: 20px;
        }

        button {
          margin-top: 1rem;
          border-radius: 20px;
          width: 100%;
        }
      }

      & div > div:last-child {
        max-height: 100%;
        overflow-y: auto;
        margin-right: 1rem;

        & > h4 {
          color: $main_blue_lighter;
        }

        & > table > tbody > tr:nth-of-type(odd) {
          background-color: $table_row_dimmed;
        }
      }
    }
  }
}