// Imports
@import '../variables';
@import '../animations';
@import '../mixins';

.Sidebar {
	height: 100vh;
	width: $sidebar_width_closed;
	background: $main_gradient;
	overflow: hidden;
	transition: width 0.5s ease-out;
	white-space: nowrap;
	position: relative;

	// position: sticky;
	// top: 0;
	// left: 0;
}

.SidebarList {
	width: 100%;
	padding-left: $SidebarList_padding_left;
	padding-top: 40px;
}

.extended {
	width: $sidebar_width_extended;
}

.Sidebar .SidebarRow {
	position: relative;
	list-style: none;
	width: 100%;
	display: flex;
	padding: 1rem 0.5rem;
	margin: 1rem 0;
	color: $main_white;
	font-weight: bold;
	font-size: 1.4rem;
	cursor: pointer;
	transition: all 0.3s ease-out;

	a {
		width: 100%;
		display: flex;
		color: inherit;
	}

	&:hover:not(#toggleWidthBtn) {
		//color: gray;
		transform: scale(1.1);
	}

	& .SidebarTitle {
		position: absolute;
		margin-left: 4rem;
	}
}

.Sidebar .SidebarRow .SidebarIcon {
	margin-right: 2.5rem;
}

.Sidebar #Active {
	background-color: $main_white;
	border-top-left-radius: 1.4rem;
	border-bottom-left-radius: 1.4rem;
	width: 100%;
	//height: 100%;
	pointer-events: none;
	/* margin-left: 0.5rem; */
}

.Sidebar #Active * {
	color: $main_blue_darker;
}

.Sidebar #Active span:nth-child(1) {
	position: absolute;
	left: 0;
	top: -20px;
	height: 20px;
	width: $span_width;
	background-color: $main_white;
}

.Sidebar #Active span:nth-child(1)::before {
	content: '';
	position: absolute;
	left: -$SidebarList_padding_left;
	top: 0;
	height: 100%;
	width: $span_gradient_width;
	border-bottom-right-radius: 20px;
	/* background-color: #4d5bf9; */
	background: $main-gradient;
}

.Sidebar #Active span:nth-child(2) {
	position: absolute;
	left: 0;
	bottom: -20px;
	height: 20px;
	width: $span_width;
	background-color: $main_white;
}

.Sidebar #Active span:nth-child(2)::before {
	content: '';
	position: absolute;
	left: -$SidebarList_padding_left;
	top: 0;
	height: 100%;
	width: $span_gradient_width;
	border-top-right-radius: 20px;
	/* background-color: #4d5bf9; */
	background: $main_gradient;
}

#toggle {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: 50px;
	height: 50px;
	border-radius: 15px;
	/* background-color: #4d5bf9; */
	background: $main_gradient;
}

// Positioning first and last list items
#toggleWidthBtn {
	margin-left: 1rem;
	font-size: 2rem;
	text-transform: uppercase;
	letter-spacing: 0.5rem;
	cursor: pointer;
}

.Sidebar .SidebarRow:last-child {
	position: absolute;
	bottom: 0;
}
