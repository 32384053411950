@import '../../variables';
@import '../../animations';
@import '../../mixins';

#quick-mail-attachments-modal {
  //overflow: auto;

  width: 100%;
  max-width: 800px;
  min-width: 400px;

  height: 100%;
  max-height: 580px;
  min-height: 300px;

  .modal-header {
    h4 {
      margin: 0;
    }

    button {
      border-radius: 20px;
    }
  }

  .modal-body {
    overflow: auto;
    width: 100%;
    max-height: 500px;
    min-height: 220px;
  }

  .modal-footer h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 85%;
  }

}

#quick-mail-attachments-table {
  th:not(th:nth-of-type(3)) {
    //position: sticky;
    //background-color: #fff;
    //background-color: #cccccc05;
    //backdrop-filter: blur(10px);
    //top: 0;
    text-align: center;

    //tr:not(th:nth-of-type(3)) {
    //  text-align: center;
    //}
  }

  tbody {
    overflow: hidden;
    height: 20px;
    max-height: 200px;

    td {
      vertical-align: middle;

      &:not(td:nth-of-type(3)) {
        text-align: center;
      }
    }
  }

  & img {
    filter: drop-shadow(0 0 3px #999);
  }
}